<template>
  <Modal addClass="modal-movie modal-movie-no-group" :showLoader="showLoader">
    <ModalHeaderVod
      ref="header"
      :asset="asset"
      @play="onPlay"
      @favorite="onFavorite"
      @score="onChangeScore"
      :poster="poster()"
      :posterMini="miniPoster()"
      :key="keyCode"
    />

    <div class="modal-bottom fade" :class="{ show: showBottom }">
      <h6>Explora otros títulos similares</h6>

      <div class="view-render">
        <template v-if="similarAssets.length > 0">
          <Slider :data="similarAssets">
            <template slot-scope="itemslider">
              <CardVOD
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset"
                :data="item"
                :sliderId="'titulos-similares'"
                :sliderTitle="'Explora otros títulos similares'"
                :cardId="'card-' + index"
              />
            </template>
          </Slider>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "ModalMovie",

  components: {
    Modal: () => import("@/components/Modal.vue"),
    ModalHeaderVod: () => import("@/components/ModalHeaderVod.vue"),
    Slider: () => import("@/components/Slider.vue"),
    CardVOD: () => import("@/components/Card-VOD.vue"),
  },

  data() {
    return {
      asset: {
        userRating: 0,
        rating: 0,
        favorite: false,
      },
      similarAssets: [],
      showLoader: true,
      showBottom: false,
      componentOrigin: undefined,
      keyCode: 1,
    };
  },

  mounted() {
    this.$bus.$on("modal-movie:show", this.show);
  },

  beforeDestroy() {
    this.$bus.$off("modal-movie:show", this.show);
  },

  methods: {
    poster() {
      return telecentro.tplay.core.api.traerPosterHWPeliculas(
        this.asset.idAsset,
        360,
        240
      );
    },

    miniPoster() {
      return telecentro.tplay.core.api.traerPosterHWPeliculas(
        this.asset.idAsset,
        1,
        1
      );
    },

    show(obj) {
      const self = this;
      if (this.showBottom === false) {
        tplay.resetVueData(self);
      }

      telecentro.tplay.core.helpers.join(
        __.partial(showModal, ".modal-movie-no-group"),
        function (done) {
          //Es necesario llenar el buffer de favoritos para que los constructores de Pelicula puedan llenar el campo "favorite"
          telecentro.tplay.core.favoritos.traerFavoritos(function (
            arrFavoritos
          ) {
            telecentro.tplay.core.peliculas.datosDePelicula(
              obj.data.idAsset,
              function (ret) {
                if (gtag) {
                  gtag("event", "Detalle película", {
                    event_category: "MODAL-MOVIE",
                    event_action: "Detalle película",
                    event_label: ret.title,
                    value: "0",
                  });
                }

                //Asigno con Object.assign para no perder la reactividad en las subpropiedades de asset.
                //Object.assign(self.asset, ret);
                self.asset = obj.data;

                self.asset.title =
                  telecentro.tplay.core.helpers.string.replaceUnicode(
                    self.asset.title
                  );
                self.asset.synopsis =
                  telecentro.tplay.core.helpers.string.replaceUnicode(
                    self.asset.synopsis
                  );

                self.componentOrigin = obj.componentOrigin;

                this.keyCode++;

                telecentro.tplay.core.recomendaciones.peliculas(
                  self.asset,
                  20,
                  function (peliculas) {
                    self.similarAssets = peliculas;
                    done();
                  }
                );
              }
            );
          });
        }
      )(function () {
        self.showLoader = false;

        //Espero a que se aplique el showLoader
        setTimeout(function () {
          self.showBottom = true;
        }, 100);
      });
    },

    onFavorite() {
      const self = this;

      if (self.asset.favorite) {
        telecentro.tplay.core.favoritos.quitarFavoritos(
          self.asset,
          function (ret) {
            self.asset.favorite = false;
          }
        );
      } else {
        telecentro.tplay.core.favoritos.agregarFavoritos(
          self.asset,
          function (ret) {
            self.asset.favorite = true;
          }
        );
      }
    },

    onPlay() {
      if (gtag) {
        gtag("event", "Reproducir desde modal", {
          event_category: "MODAL-MOVIE",
          event_action: "Reproducir desde modal",
          event_label: this.asset.title,
          value: "0",
        });
      }

      this.$bus.$emit("player:set-source", this.asset);
      tplay.sendAudience(this.asset, this.componentOrigin);
    },

    onChangeScore(userRating) {
      const self = this;

      telecentro.tplay.core.vod.cambiarScoreAsset(
        self.asset.idAsset,
        userRating,
        function (rating) {
          self.asset.rating = rating;
          self.asset.userRating = userRating;
        }
      );
    },
  },
};
</script>
